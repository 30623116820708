<template>
    <table class="table table-condensed table-striped" style="width: 100%;">
        <thead>
        <tr>
            <th>Test Type</th>
            <th>Language</th>
            <th>Price</th>
            <th>Count</th>
            <th class="text-right">Subtotal</th>
            <th>Remove</th>
        </tr>
        </thead>
        <tbody>

        <template v-if="test_counts.length !== 0">
            <template v-for="(test,index) in test_counts">
                <tr v-if="!isEmpty(test.test)">
                    <td>{{ test.test.short_name }}</td>
                    <td>{{ test.test.language }}</td>
                    <td>${{ test.test.cost }}</td>
                    <td class="col-xs-3">
                        <order-form-spinner :id="test.test.id" :cost="test.test.cost" :order_count="test.count" :total_count="total_count" :guest="guest" :aria_label="test.test.id === 1 ? 'RHETI test count' : 'IVQ test count'" ></order-form-spinner>
                    </td>
                    <td class="text-right" :id="'order_total_' + index">${{ (test.test.cost * test.count).toFixed(2) }}</td>
                    <td>
                        <button v-on:click.prevent="remove(test.test.id)" class="btn btn-danger btn-sm" :aria-label="test.test.short_name === 'RHETI' ? 'Remove RHETI Test from cart' : 'Remove IVQ Test from cart'">
                            <span class="glyphicon glyphicon-remove"></span>
                        </button>
                    </td>
                </tr>
                <tr v-if="!isEmpty(test.discount)">
                    <td>
                        Discount - {{ test.discount.discount.amount}}%
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="text-right" id="discountAmount">
                        (${{ test.discount.discountAmount.toFixed(2)}})
                    </td>
                    <td></td>
                </tr>
            </template>
        </template>
        </tbody>
        <tfoot id="discountTotalRow">
        <tr v-if="test_counts.length !== 0">
            <td>Total</td>
            <td></td>
            <td></td>
            <td></td>
            <td class="text-right" id="totalWithDiscount">
                ${{total_amount.toFixed(2)}}
            </td>
            <td></td>
        </tr>
        </tfoot>
    </table>
</template>
<script>
    import _ from 'lodash';
    import OrderFormSpinner from './OrderFormSpinner.vue';
    import {mapState} from 'vuex';

    export default {
        data() {
            return {}
        },
        props: [
            "guest"
        ],
        computed: {
            ...mapState("cart", [
                'test_counts',
                'total_amount',
                'total_count'
            ])
        },
        components: {
            OrderFormSpinner
        },
        methods: {
            isEmpty(value) {
                return _.isEmpty(value)
            },
            remove(testId) {
                window.axios.put("/orders/remove/" + testId)
                    .then(({data}) => {
                        window.location.reload();
                    }).catch(({response}) => {
                    console.log("error: " + response);
                });
            }
        }
    }
</script>
