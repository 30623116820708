<template>
  <div>
    <el-row v-if="questions[activeQuestionIndex] && questions[activeQuestionIndex].answers.length > 0" :gutter="40">
      <el-col :md="{span: 18, offset: 3}" :sm="13" :xs="24">
        <h3>{{ localText.question }} {{ activeQuestionIndex + 1 }} <span>{{ localText.of }} {{ totalQuestions }}</span></h3>  
        <el-row v-for="(choice, choiceIdx) in questions[activeQuestionIndex].answers" :key="choiceIdx" class="radio test-choice">
          <el-radio v-model="formData.answer_id" :label="choice.id">
            <span>{{ trans(choice.answer) }} </span>
          </el-radio>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>
<style>
.el-radio__label {
  color: #000000;
  font-weight: 400;
}

.el-radio {
  white-space: normal;
}
</style>
<script>
// import { loadLanguageAsync } from 'laravel-vue-i18n';
//
// import { I18n } from 'laravel-vue-i18n'
//
// const resolver = lang => import(`../../lang/${lang}.json`)
//
// const i18nEn = new I18n({
//     lang: 'en',
//     resolve: resolver
// })
// const i18nEs = new I18n({
//     lang: 'es',
//     resolve: resolver
// })

import {mapState} from "vuex";
import {trans} from "laravel-vue-i18n";

export default {
  data() {
    return {
      formData: {
        answer_id: null
      }
    }
  },
  props: [
    'localText',
    'totalQuestions'
  ],
  computed: {
    ...mapState('test', ['questions', 'answers', 'activeQuestionIndex',]),
  },
  methods: {
    trans,
    setup() {
      console.log('called');
      if (this.answers[this.activeQuestionIndex]) {
        this.formData.answer_id = this.answers[this.activeQuestionIndex].answer_id;
      } else {
        this.formData.answer_id = null;
      }
    }
  },
  watch: {
    formData: {
      handler(val) {
        this.$emit('questionAnswered', {
          question_id: this.questions[this.activeQuestionIndex].id,
          answer_id: val.answer_id,
        })
      },
      deep: true
    },
    activeQuestionIndex() {
      this.setup();
    }
  },
  created() {
    this.setup();
  }
}
</script>