<template>
  <div id="payment-form">
    <el-button
      v-if="hasNonTaxErrors"
      icon="pointer"
      type="default"
      @click="resetPayment"
      >Try Again</el-button
    >
    <div v-if="!isStripeLoaded">
      <el-alert class="tax-alert" title="Error" type="error" show-icon>
        <span
          >Due to technical difficulties, we cannot complete your transaction at
          this time.</span
        ><br />
        <span
          >We apologize for any inconvenience and expect the issue to be
          resolved shortly.</span
        ><br />
        <span>Please try again later.</span>
      </el-alert>
    </div>
    <StripeElements
      v-else
      :stripe-key="stripe.key"
      :instance-options="stripe.instanceOptions"
      :elements-options="stripe.elementOptions"
      #default="{ elements }"
      ref="elms"
    >
      <el-form :label-position="isMobile ? 'top' : 'left'" label-width="200px">
        <el-form-item
          class="label-black"
          label="Cardholder Name"
          :error="getValidationError('cardholder_name')"
        >
          <el-input
            label="Cardholder Name"
            v-model="form.cardholder_name"
            @input="clearValidationError('cardholder_name')"
            id="cardholderName"
            ref="cardholderName"
            :disabled="!paymentIntent"
          ></el-input>
        </el-form-item>
        <el-form-item class="label-black" label="Card Number">
          <StripeElement
            type="cardNumber"
            :elements="elements"
            :options="stripe.elementOptions"
            ref="cardNumber"
            @change="paymentInfoChanged"
            style="width: 100%"
          />
          <p class="text-danger" role="alert">{{ stripe.errors.cardNumber }}</p>
        </el-form-item>
        <el-form-item class="label-black" label="Expiration (MM/YY)">
          <StripeElement
            type="cardExpiry"
            :elements="elements"
            :options="stripe.elementOptions"
            ref="cardExpiry"
            @change="paymentInfoChanged"
            style="width: 100%"
          />
          <p class="text-danger" role="alert">{{ stripe.errors.cardExpiry }}</p>
        </el-form-item>
        <el-form-item class="label-black" label="CVC">
          <StripeElement
            type="cardCvc"
            :elements="elements"
            :options="stripe.elementOptions"
            ref="cardCvc"
            @change="paymentInfoChanged"
            style="width: 100%"
          />
          <p class="text-danger" role="alert">{{ stripe.errors.cardCvc }}</p>
        </el-form-item>
        <div class="terms-row">
          <el-checkbox :value="termsAccepted" @change="updateTermsAccepted">
            I acknowledge and agree that my use of The Enneagram Institute&reg;
            website, including the online tests, is governed by these
            <a href="#" @click.prevent="toggleModal">Terms of Use</a>.
          </el-checkbox>
          <el-alert
            class="tax-alert"
            v-if="isTermsErrorShowing"
            title="Warning"
            type="warning"
            show-icon
          >
            <span
              >Must acknowledge & accept terms of use to continue with
              purchase.</span
            >
          </el-alert>
        </div>
        <el-form-item class="form-btn-group">
          <el-button type="secondary" @click="back">Back</el-button>
          <el-button
            type="primary"
            @click="pay"
            :loading="isLoading"
            :disabled="hasErrors || !paymentIntent"
          >
            Buy Now
          </el-button>
        </el-form-item>
      </el-form>
    </StripeElements>
    <Modal v-if="isModalVisible" type="termsAndConditions" @close="toggleModal">
      <template v-slot:header>
        <h3 style="color: #333">{{ terms.termsHeader }}</h3>
      </template>
      <template v-slot:content>
        <textarea readonly rows="20" class="form-control">
          {{ terms.terms }}
        </textarea>
      </template>
    </Modal>
  </div>
</template>

<script>
import { StripeElement, StripeElements } from "vue-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import FormValidation from "../../mixins/FormValidation";
import Modal from "./Modal/Modal.vue";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
  name: "PaymentForm",
  components: {
    StripeElement,
    StripeElements,
    Modal,
  },
  props: {
    terms: Object,
  },
  mixins: [FormValidation],
  data() {
    return {
      isStripeLoaded: true,
      isLoading: false,
      isTermsErrorShowing: false,
      isModalVisible: false,
      isTryAgainActionVisible: false,
      form: {
        cardholder_name: "",
      },
      stripe: {
        key: import.meta.env.VITE_STRIPE_PUBLIC,
        instanceOptions: {
          stripeAccount: import.meta.env.VITE_STRIPE_ACCOUNT,
          apiVersion: import.meta.env.VITE_STRIPE_API_VERSION,
        },
        elementOptions: {
          disabled: true,
          style: {
            base: {
              color: "#555",
              fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
              fontSize: "16px",
            },
            invalid: {
              iconColor: "#a94442",
              color: "#a94442",
            },
          },
          classes: {
            base: "form-control",
            focus: "focus",
            empty: "empty",
            invalid: "invalid",
          },
        },
        errors: {
          cardNumber: "",
          cardExpiry: "",
          cardCvc: "",
        },
      },
    };
  },
  computed: {
    ...mapState("cart", [
      "errors",
      "termsAccepted",
      "isMobile",
      "paymentIntent",
    ]),
    ...mapGetters("cart", ["paymentIntentAmountMatchesCartAmount"]),
    hasErrors() {
        return (
        this.hasNonTaxErrors || 
        this.hasTaxErrors
      );
    },
    hasNonTaxErrors() {
        return (
            this.stripe.errors.cardNumber !== "" ||
            this.stripe.errors.cardExpiry !== "" ||
            this.stripe.errors.cardCvc !== "" ||
            this.findErrorByKey("payment") !== null
        );
    },
    hasTaxErrors() {
        return (
            this.findErrorByKey("tax") !== null
        );
    }
  },
  methods: {
    ...mapMutations("cart", [
      "removeErrors",
      "updateCheckoutStep",
      "updatePayment",
      "updateTermsAccepted",
    ]),
    ...mapActions("cart", [
      "getTax",
      "createPaymentIntent",
      "updatePaymentIntent",
    ]),
    back() {
      this.removeErrors("payment");
      this.removeErrors("tax");
      this.updateCheckoutStep(1);
    },
    toggleModal() {
      this.isModalVisible = !this.isModalVisible;
    },
    findErrorByKey(key) {
      return this.errors[key];
    },
    pay() {
      const groupComponent = this.$refs.elms;
      const cardElement = this.$refs.cardNumber.stripeElement;

      if (this.form.cardholder_name === "") {
        this.validationErrors = {
          cardholder_name: ["The cardholder name field is required"],
        };
      } else if (!this.termsAccepted) {
        this.isTermsErrorShowing = true;
      } else {
        this.isLoading = true;
        this.updatePayment({
          cardholder_name: this.form.cardholder_name,
          groupComponent: groupComponent,
          cardElement: cardElement,
        });
        this.$emit("pay");
      }
    },
    paymentInfoChanged(event) {
      if (event.elementType === "cardNumber") {
        this.removeErrors("payment");
        this.isLoading = false;
        if (event.error) {
          this.stripe.errors.cardNumber = event.error.message;
        } else {
          this.stripe.errors.cardNumber = "";
        }
      }
      if (event.elementType === "cardExpiry") {
        this.removeErrors("payment");
        this.isLoading = false;
        if (event.error) {
          this.stripe.errors.cardExpiry = event.error.message;
        } else {
          this.stripe.errors.cardExpiry = "";
        }
      }
      if (event.elementType === "cardCvc") {
        this.removeErrors("payment");
        this.isLoading = false;
        if (event.error) {
          this.stripe.errors.cardCvc = event.error.message;
        } else {
          this.stripe.errors.cardCvc = "";
        }
      }
    },
    focusCardholderName() {
      this.$refs.cardholderName.focus();
    },
    disableStripeElements(bool) {
      this.$refs.cardNumber.stripeElement.update({ disabled: bool });
      this.$refs.cardCvc.stripeElement.update({ disabled: bool });
      this.$refs.cardExpiry.stripeElement.update({ disabled: bool });
    },
    resetPayment() {
      this.$refs.cardNumber.stripeElement.clear();
      this.$refs.cardCvc.stripeElement.clear();
      this.$refs.cardExpiry.stripeElement.clear();
      this.disableStripeElements(false);
    },
  },
  watch: {
    hasErrors(val) {
      if (val) {
        this.isLoading = false;
      }
    },
    termsAccepted(val) {
      if (val) {
        this.isTermsErrorShowing = false;
      }
    },
    formValidationErrors: {
      handler(errors) {
        this.validationErrors = errors;
      },
      deep: true,
    },
  },
  async mounted() {
    try {
      this.removeErrors("address");
      // Get Tax
      await this.getTax();

      // If getting tax results in an error for address, it's because we 
      // Are ignoring some validation on Avalara address resolution
      // And so tax is serving as "fallback" validation   
      if (this.findErrorByKey("address") !== null) {
        return;
      }

      // If PaymentIntent has not been created/saved to memory
      if (!this.paymentIntent) {
        try {
          // ... Create the PaymentIntent
          await this.createPaymentIntent();
          this.disableStripeElements(false);
        } catch (e) {
          this.disableStripeElements(true);
          this.isTryAgainActionVisible = true;
        }
        // If PaymentIntent exists, but the amount !== amount + tax in Cart
      } else if (!this.paymentIntentAmountMatchesCartAmount) {
        try {
          // ... Update the PaymentIntent with the amount + tax in Cart
          await this.updatePaymentIntent();
          this.disableStripeElements(false);
        } catch (e) {
          this.disableStripeElements(true);
        }
      } else {
        this.disableStripeElements(false);
      }
    } catch (e) {
      console.log(e);
    }

    this.$nextTick(() => {
      this.focusCardholderName();
    });

    if (this.$refs.elms.$data.instance == "Stripe v3 library is not loaded") {
      let testResult;
      try {
        testResult = await window.axios.post("/payment/api/stripe-not-loading");
      } catch (error) {}
      this.isStripeLoaded = false;
    }
  },
};
</script>

<style scoped>
.terms-row {
  margin-bottom: 10px;
}
</style>
