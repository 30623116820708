<template>
  <li>
    <el-dropdown trigger="click">
      <span
        id="shopping-cart"
        ref="shoppingCart"
        aria-label="Shopping cart"
        class="fa-stack fa-2x has-badge el-dropdown-link"
      >
        <i class="fa fa-stack-2x"></i>
        <i class="fa fa-shopping-cart fa-stack-1x"></i>
      </span>
      <template #dropdown>
        <el-dropdown-menu
          v-if="test_counts"
          slot="dropdown"
          class="shopping-cart-dropdown"
        >
          <div v-if="total_count">
            <p>( {{ total_count }} ) Tests</p>
            <template v-if="rheti_counts.length !== 0">
              <div class="gold">RHETI</div>
              <template v-for="test in rheti_counts">
                <div>
                  {{ test.count }} {{ test.test.language }}
                  <span class="pull-right"
                    >${{ (test.count * test.test.cost).toFixed(2) }}</span
                  >
                </div>
                <div v-if="test.discount.length !== 0">
                  Discount - {{ test.discount.discount.amount }}%
                  <span class="pull-right"
                    >(${{ test.discount.discountAmount.toFixed(2) }})</span
                  >
                </div>
              </template>
              <div class="divider"></div>
            </template>
            <template v-if="ivq_counts.length !== 0">
              <div class="gold">IVQ</div>
              <template v-for="test in ivq_counts" class="test-details">
                <div>
                  {{ test.count }} {{ test.test.language }}
                  <span class="pull-right"
                    >${{ (test.count * test.test.cost).toFixed(2) }}</span
                  >
                </div>
                <div v-if="test.discount.length !== 0">
                  Discount - {{ test.discount.discount.amount }}%
                  <span class="pull-right"
                    >(${{ test.discount.discountAmount.toFixed(2) }})</span
                  >
                </div>
              </template>
              <div class="divider"></div>
            </template>
            <div class="gold">
              TOTAL<span class="pull-right"
                >${{ total_amount.toFixed(2) }}</span
              >
            </div>
            <div class="divider"></div>
            <a class="center" href="/orders">
              <el-dropdown-item class="el-dropdown-item">
                View Cart
              </el-dropdown-item>
            </a>
          </div>
          <div v-else class="text-center">
            <p><em>Your cart is empty</em></p>
            <div class="divider"></div>
            <a href="/orders/create" class="">
              <el-dropdown-item class="el-dropdown-item">
                Order Test
              </el-dropdown-item>
            </a>
          </div>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </li>
</template>
<script>
import { mapState } from "vuex";

export default {
  computed: {
    rheti_counts: function () {
      return this.test_counts.filter((test) => {
        return test.test.short_name === "RHETI";
      });
    },
    ivq_counts: function () {
      return this.test_counts.filter((test) => {
        return test.test.short_name === "IVQ";
      });
    },
    ...mapState("cart", ["total_count", "test_counts", "total_amount"]),
  },
  created() {
    this.$store.dispatch("cart/refreshCart").catch(({ response }) => {
      console.log("error: ", response);
    });
  },
  watch: {
    total_count: function (newVal, oldVal) {
      const shoppingCart = this.$refs.shoppingCart;
      shoppingCart.dataset.count = this.total_count;
    },
  },
};
</script>

<style>
.divider {
  border-bottom: solid #f2f2f2 1px;
  padding-bottom: 5px;
}

.test-details {
  font-size: 12px;
}

.gold {
  color: rgba(207, 162, 24, 1);
}

.el-dropdown-item {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
