<template>
  <el-row :gutter="60" class="checkout-form-row">
    <el-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="7"
      :xl="7"
      class="checkout-form-col"
    >
      <Cart
        :total="total"
        :expiry-days="expiryDays"
        :authenticated="authenticated"
        :faq="faq"
        @add-coupon="addCoupon"
      />
    </el-col>
    <el-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="10"
      :xl="10"
      class="checkout-form-col"
    >
      <BusinessAccount @dismiss-panel="dismissPanel" v-show="isPanelVisible" />
      <el-alert
        class="tax-alert"
        v-if="errors.tax"
        title="Error"
        type="error"
        show-icon
      >
        <span
          >Due to technical difficulties, we cannot complete your transaction at
          this time.</span
        ><br />
        <span
          >We apologize for any inconvenience and expect the issue to be
          resolved shortly.</span
        ><br />
        <span>Please try again later.</span>
      </el-alert>
      <el-steps :active="checkoutStep" simple finish-status="success">
        <el-step title="Contact"></el-step>
        <el-step title="Address"></el-step>
        <el-step title="Payment"></el-step>
      </el-steps>
      <el-dialog
        v-model="isShoppingCartLost"
        width="30%"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
      >
        <template #header="{ titleId, titleClass }">
          <div class="shopping-cart-dialog-header">
            <el-icon
              class="el-icon--left"
              style="color: #eb3223; font-size: 3rem">
              <WarningFilled />
            </el-icon>
            <h4 :id="titleId" :class="titleClass" style="font-weight: 600">
              Your Shopping Cart
            </h4>
          </div>
        </template>
        <span style="font-weight: 600; word-break: normal">
          Your cart has timed out. Please add your tests to your cart again to
          continue checking out.
        </span>
        <div style="padding-top: 4em">
          <el-button
            size="large"
            type="primary"
            @click="backToOrder()"
            style="font-size: medium"
          >
            <el-icon
              class="el-icon--left"
              style="font-size: 1.3em; justify-content: flex-start">
              <ArrowLeftBold />
            </el-icon>
            Select Tests
          </el-button>
        </div>
      </el-dialog>
      <CheckoutForm
        class="checkout-form"
        :terms="terms"
        :organizations="organizations"
        @validatePurchaser="handlePurchaser"
        @validateAddress="handleAddress"
        @pay="pay"
      />
    </el-col>
  </el-row>
</template>

<script>
import CheckoutForm from "./CheckoutForm.vue";
import BusinessAccount from "./BusinessAccount.vue";
import Cart from "./Cart.vue";
import FormValidation from "../../mixins/FormValidation";
import { mapActions, mapMutations, mapState } from "vuex";
import AddressVerification from "./AddressVerification.vue";

export default {
  name: "Checkout.vue",
  components: { AddressVerification, CheckoutForm, Cart, BusinessAccount },
  mixins: [FormValidation],
  data() {
    return {
      isPanelVisible: false,
      isShoppingCartLost: false,
    };
  },
  props: {
    cart: Object,
    total: Number,
    expiryDays: Number,
    organizations: Array,
    authenticated: Boolean,
    terms: Object,
    faq: Object,
  },
  computed: {
    ...mapState("cart", [
      "checkoutCart",
      "checkoutStep",
      "purchaser",
      "address",
      "payment",
      "termsAccepted",
      "errors",
      "paymentIntent",
      "order",
    ]),
  },
  methods: {
    backToOrder() {
      window.location.href = route("orders.create");
    },
    async handlePurchaser() {
      try {
        await this.validatePurchaser();
      } catch (e) {
        console.log(e);
      }
    },
    async handleAddress() {
      try {
        await this.validateAddress();
      } catch (e) {
        console.log(e);
      }
    },
    async addCoupon(promoCode) {
      try {
        await this.applyDiscount(promoCode);
        this.removeErrors("promoCode");
      } catch ({ response }) {
        console.log(response);
      }
    },
    async pay() {
      // check session for cart first Re: #978 - Prod Error in Avalara Log
      const cartStatus = await axios.get(route("payment.check-cart"));

      if (cartStatus.data === 404) {
        this.isShoppingCartLost = true;
      } else {
        this.payment.groupComponent.instance
          .confirmCardPayment(
            this.paymentIntent.client_secret,
            {
              payment_method: {
                card: this.payment.cardElement,
                billing_details: {
                  name: this.payment.cardholder_name,
                  address: {
                    line1: this.address.line_1,
                    line2: this.address.line_2,
                    city: this.address.city,
                    state: this.address.state,
                    postal_code: this.address.zip,
                    country: this.address.country,
                  },
                },
              },
              return_url: import.meta.env.VITE_APP_URL + "/payment/checkout",
            },
            // Disable the default next action handling.
            { handleActions: true }
          )
          .then(async (paymentIntentResult) => {
            // Display error.message in UI
            if (paymentIntentResult.error) {
              this.updateErrors({
                payload: paymentIntentResult.error.message,
                field: "payment",
              });
            } else {
              // Payment Succeeded
              // Handle Order
              try {
                await this.activateOrder();
                window.location.href =
                  "/payment/paymentConfirmation/" + this.order.id;
              } catch (e) {
                console.log(e);
              }
            }
          });
      }
    },
    dismissPanel() {
      this.isPanelVisible = false;
    },
    keepAlive() {
      // Ping the server endpoint every 10 minutes to maintain session
      setInterval(() => {
        axios
          .post("/keep-alive")
          .then(() => {})
          .catch(() => {});
      }, 600000);
    },
    ...mapMutations("cart", [
      "updateCheckoutCart",
      "updateCheckoutStep",
      "updateErrors",
      "updateValidatedAddress",
      "removeErrors",
    ]),
    ...mapActions("cart", [
      "applyDiscount",
      "validatePurchaser",
      "validateAddress",
      "pingAvalara",
      "activateOrder",
    ]),
  },
  created() {
    this.updateCheckoutCart(this.cart);
    this.pingAvalara();
    this.keepAlive();
    if (!this.authenticated) {
      this.isPanelVisible = true;
    }
  },
};
</script>
<style scoped>
.tax-alert {
  margin-bottom: 22px;
}

.checkout-form {
  margin-top: 10px;
}

.checkout-form-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px;
}

@media (max-width: 992px) {
  .checkout-form-row {
    flex-direction: column;
  }
}
.shopping-cart-dialog-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: #ffeeee;
  padding: 0.5em;
}
</style>
