<template>
  <div class="panel panel-default business-panel" style="position: relative; height: auto">
    <button @click="dismiss" id="business-panel-remove-panel" type="button" class="close"><span aria-hidden="true">&times;</span></button>
    <div class="panel-body">
      <h4 class="accessible-gold checkout-panel-title">Need to purchase as a Customer Account?</h4>
      <p>
        Log in to your customer account or create one before completing your purchase.
      </p>
      <p class="text-right">
        <a style="padding: 6px 20px" href="/business-login" class="btn btn-primary">Register or Log In</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "BusinessAccount",
  methods: {
    dismiss() {
      this.$emit('dismiss-panel');
    }
  }
}
</script>

<style scoped>

</style>
