<template>
  <div
    class="panel panel-default"
    :class="{ 'business-panel': authenticated }"
    style="height: auto"
  >
    <div class="panel-body">
      <h4 class="checkout-panel-title">Summary</h4>
      <!--TODO look into business-panel class-->
      <table id="cart-contents" class="table table-condensed">
        <thead>
          <tr>
            <th>Item</th>
            <th class="text-right">Price</th>
            <th class="text-right">Qty</th>
            <th class="text-right">Total</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="checkoutCart" v-for="item in checkoutCart.contents">
            <tr>
              <td>
                {{ item.test.short_name }}
              </td>
              <td class="text-right">
                {{ formatPrice(item.test.cost) }}
              </td>
              <td class="text-right">
                {{ item.count }}
              </td>
              <td class="text-right">
                {{ formatPrice(item.test.cost * item.count) }}
              </td>
            </tr>
            <tr v-if="item.discount.length > 0 || Object.entries(item.discount).length > 0">
              <td>Discount - {{ item.discount.discount.amount }} %</td>
              <td></td>
              <td></td>
              <td class="text-right" id="discountAmount">
                ( - {{ formatPrice(item.discount.discountAmount) }} )
              </td>
            </tr>
          </template>
        </tbody>
        <tfoot id="discountTotalRow">
          <tr>
            <td><b>Subtotal</b></td>
            <td></td>
            <td></td>
            <td class="text-right" id="totalWithDiscount">
              <b>{{ formatPrice(checkoutCart.totals.totalWithDiscount) }}</b>
            </td>
          </tr>
          <tr v-show="checkoutCart.tax !== null">
            <td>
              <a href="#" @click.prevent="toggleModal"><b>Estimated Tax </b><span class="glyphicon glyphicon-question-sign"></span></a>
            </td>
            <td></td>
            <td></td>
            <td class="text-right" id="tax">
              <b>{{ formatPrice(checkoutCart.totals.estimatedTax) }}</b>
            </td>
          </tr>
          <tr v-show="checkoutCart.tax !== null">
            <td><b>Total</b></td>
            <td></td>
            <td></td>
            <td class="text-right" id="totalWithTax">
              <b>{{ formatPrice(checkoutCart.totals.totalWithTax) }}</b>
            </td>
          </tr>
        </tfoot>
      </table>
      <p>
        <b>
          <template
            v-if="checkoutCart.totals.totalCount === 1 && checkoutCart.contents.length === 1">
            This test code is valid for one-time use and expires on
            {{ testCodeExpiration }}.
          </template>
          <template v-else>
            Each test code is valid for one-time use and expires on
            {{ testCodeExpiration }}.
          </template>
        </b>
      </p>
      <div>
              <label for="promo_code">Promo Code</label>
        <el-row type="flex" :gutter="10">
          <el-col span="14">
            <el-input
              placeholder="Promo Code"
              v-model="promoCode"
              class="input-with-select"
              id="promo_code"
            />
          </el-col>
          <el-col span="10">
            <el-button type="primary" @click="addCoupon">Add Promo Code</el-button>
          </el-col>
        </el-row>
        <br />
        <el-alert
          @close="showAlert = false"
          v-show="showAlert"
          title="Error"
          type="error"
          :description="findErrorByKey('promoCode')"
          show-icon
        >
        </el-alert>
      </div>
      <br />
      <div>
        <a href="https://www.enneagraminstitute.com/refund-policy">Refund Policy</a>
      </div>
      <br />
      <p>
        <a href="/orders"><span class="glyphicon glyphicon-chevron-left"></span> Return to Cart</a>
      </p>
    </div>
    <Modal
      type="taxFaq"
      v-if="isModalVisible"
      @close="toggleModal"
    >
      <template v-slot:header>
        <h3 style="color: #333">{{faq.faqHeader}}</h3>
      </template>
      <template v-slot:content>
        <h4>{{faq.questionAnswers.header}}</h4>
        <ul class="faq-list">
          <li v-for="(question, i) in faq.questionAnswers.questions">
            <b>{{question}}</b>
            <p v-html="faq.questionAnswers.answers[i]"></p>
          </li>
        </ul>
        <p><b>{{faq.disclaimer.header}}</b></p>
        <p v-html="faq.disclaimer.text"></p>
      </template>
    </Modal>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import Modal from "./Modal/Modal.vue";

export default {
  name: "Cart",
  props: {
    total: Number,
    expiryDays: Number,
    authenticated: Boolean,
    faq: Object,
  },
  data() {
    return {
      promoCode: "",
      showAlert: false,
      isModalVisible: false,
    };
  },
  components: {
    Modal
  },
  computed: {
    testCodeExpiration() {
      return moment()
        .add(this.expiryDays, 'days')
        .format("YYYY-MM-DD");
    },
    ...mapState("cart", ["checkoutCart", "errors"])
  },
  methods: {
    addCoupon() {
      this.$emit("add-coupon", this.promoCode);
    },
    findErrorByKey(key) {
      return this.errors[key];
    },
    toggleModal() {
      this.isModalVisible = !this.isModalVisible;
    },
    formatPrice(input) {
      return "$" + parseFloat(input).toFixed(2);
    },
  },
  watch: {
    errors: {
      handler() {
        this.showAlert = !!this.findErrorByKey("promoCode");
      },
      deep: true
    }
  }
};
</script>

<style scoped>
  .faq-list {
    list-style-type: none;
    padding-inline-start: 0;
  }
</style>
