<template>
  <form class="form-horizontal">
    <fieldset>
      <legend>Choose the number of tests and the language.</legend>
      <div class="form-group">
        <div class="col-md-4 col-sm-4">
          <label>Quantity:</label>
          <el-input-number
            v-if="guest"
            size="small"
            v-model="formData.order_count"
            controls-position="right"
            :min="1"
            :max="max_count"
            :label="
              this.test_type === 'RHETI'
                ? 'RHETI Test Quantity'
                : 'IVQ Test Quantity'
            "
          ></el-input-number>
          <el-input-number
            v-else
            size="small"
            v-model="formData.order_count"
            controls-position="right"
            :min="1"
            :label="
              this.test_type === 'RHETI'
                ? 'RHETI Test Quantity'
                : 'IVQ Test Quantity'
            "
          ></el-input-number>
        </div>
        <div class="col-sm-offset-1 col-md-4 col-sm-6 col-md-offset-0">
          <label>Language :</label>
          <select
            :aria-label="
              this.test_type === 'RHETI'
                ? 'RHETI Test Language'
                : 'IVQ Test Language'
            "
            class="form-control"
            name="language"
            v-model="formData.language"
          >
            <option
              v-if="test_options.length !== 1"
              value="null"
              selected="true"
              disabled="disabled"
              :label="
                this.test_type === 'RHETI'
                  ? 'RHETI Test Language'
                  : 'IVQ Test Language'
              "
            >
              Select
            </option>
            <option v-for="(test_option, key) in test_options" :value="key">
              {{ test_option.language }}
            </option>
          </select>
        </div>
        <div class="col-md-4 col-sm-12">
          <button
            class="btn btn-primary add-to-cart"
            data-toggle="modal"
            :disabled="formData.language === null"
            @click.prevent="submit()"
            :aria-label="
              this.test_type === 'RHETI'
                ? 'Add RHETI Test to cart'
                : 'Add IVQ Test to cart'
            "
          >
            <span class="glyphicon glyphicon-shopping-cart"></span> Add to Cart
          </button>
        </div>
      </div>
    </fieldset>

    <div
      class="modal fade"
      :id="'addCartModal-' + this.test_type"
      tabindex="-1"
      data-toggle="modal"
    >
      <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content" style="padding-bottom: 15px">
          <div class="modal-header">
            <h4
              class="modal-title"
              id="addCartModalLabel"
              style="position: relative"
            >
              Added ({{ added }}) {{ added === 1 ? "test" : "tests" }} to your
              cart
              <a href="/orders" class="view-cart-link">View Cart</a>
            </h4>
          </div>
          <div class="container-fluid" style="padding: 0px">
            <div
              v-if="test"
              class="modal-body"
              style="margin: 0px; padding: 0px 15px; font-size: 16px"
            >
              <h4 style="position: relative" v-if="formData.language !== null">
                <!--{{test_options[formData.language].language}}-->
                {{ test_type }}
                <span style="position: absolute; right: 0px; top: 0px">
                  Total ({{ total_count }}
                  {{ total_count === 1 ? "item" : "items" }}): ${{
                    total_amount.toFixed(2)
                  }}
                </span>
              </h4>
              <p>
                Qty: {{ test.count }}
                <template v-if="test.discount.length !== 0">
                  <br />
                  Discount {{ test.discount.discount.amount }}%: -${{
                    test.discount.discountAmount.toFixed(2)
                  }}
                  <br />
                  Subtotal: ${{ test.discount.totalWithDiscount.toFixed(2) }}
                </template>
                <template v-else>
                  <br />
                  Subtotal: ${{ (test.count * test.test.cost).toFixed(2) }}
                </template>
              </p>
              <p>
                Each code is valid for one-time use and expires on
                {{ expireDate }}
              </p>
              <br />
              <div class="pull-right">
                <a
                  tabindex="0"
                  @click="hideModal"
                  style="padding-right: 10px; cursor: pointer"
                  >Continue Shopping</a
                >
                <a class="btn btn-primary"  href="/payment/checkout"
                  >Proceed to Checkout</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import moment from "moment";
import { mapState } from "vuex";

export default {
  data() {
    return {
      formData: {
        language: this.test_options.length === 1 ? 0 : null,
        order_count: 1,
      },
      added: 0,
      showModal: false,
    };
  },
  filters: {
    plural: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  computed: {
    expireDate: function () {
      return moment().add(180, "days").format("YYYY-MM-DD");
    },
    max_count: function () {
      return 20 - this.total_count;
    },
    test: function () {
      if (this.formData.language === null) {
        return null;
      }
      return this.test_counts.find((test) => {
        return test.test.id === this.test_options[this.formData.language].id;
      });
    },
    ...mapState("cart", ["total_count", "test_counts", "total_amount"]),
  },
  props: ["test_type", "test_options", "guest"],
  methods: {
    submit() {
      if (this.guest && this.total_count + this.formData.order_count > 20) {
        $("#test_count_alert").show();
        $("#test_count_alert_close_button").click(function () {
          $("#test_count_alert").hide();
        });
        return;
      } else {
        $("#addCartModal-" + this.test_type ).modal("show").addClass('in');
      }

      const test_id = this.test_options[this.formData.language].id;
      this.added = this.formData.order_count;

      if (this.guest && this.formData.order_count + this.total_count > 20) {
        this.added = 20 - this.total_count;
      }

      // this.$emit('order', test_id, this.formData.order_count, this.guest);
      this.$store.dispatch("cart/order", {
        id: test_id,
        count: this.formData.order_count,
        guest: this.guest,
      });

      this.$store
        .dispatch("cart/updateCart", {
          test_type: this.test_type,
          order_count: this.formData.order_count,
          test_id: test_id,
        })
        .catch(({ response }) => {
          console.log("error: " + response);
        });
    },
    hideModal() {
      $("#addCartModal-" + this.test_type ).modal("hide").addClass('out');
    },
  },
};
</script>
<style scoped>
.el-input-number {
  margin-top: 4px;
}

.view-cart-link {
  position: absolute;
  right: 0px;
  top: 3px;
  font-size: 16px;
}

.modal-dialog {
  width: 500px;
  height: 300px;
}

</style>
